import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, List, ListItem, ListItemText } from '@mui/material';

const MessagesList = () => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        axios.get('/api/messages')
            .then(response => {
                setMessages(response.data);
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
    }, []);

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Messages
            </Typography>
            <List>
                {messages.map(message => (
                    <ListItem key={message.id}>
                        <ListItemText primary={message.message} secondary={`From: ${message.Client.name} (${message.Client.email})`} />
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export default MessagesList;
