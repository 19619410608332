import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import ClientsList from './components/ClientsList';
import MessagesList from './components/MessagesList';
import Login from './pages/Login';
import AuthContext, { AuthProvider } from './context/AuthContext';

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const { user } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      element={user ? <Element /> : <Navigate to="/login" />}
    />
  );
};

const App = () => {
  const { user, logout } = useContext(AuthContext);

  return (
    <Router>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">
            Client Dashboard
          </Typography>
          {user && (
            <Button color="inherit" onClick={logout}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/login" element={<Login />} />
        <ProtectedRoute path="/clients" element={<ClientsList />} />
        <ProtectedRoute path="/messages" element={<MessagesList />} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);
